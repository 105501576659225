<template>
    <v-dialog v-model="show"  max-width="500" v-if="resolutionScreen >= 500">
        <v-card>
                <v-card-text class="pa-0">
                <v-list class=" pa-0">
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title style="font-size:large;">
                            Update ID Card 
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pt-3">
                    <v-layout align-center justify-center
                    class="pt-3">
                        <p class="text-center" style="font-size: 18px;">
                            ไม่พบเลขบัตรประชาชนนี้บนระบบ ต้องการอัพเดตให้กับผู้ใช้นี้หรือไม่ ?
                        </p>
                </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-progress-circular
                    v-if="isloadingupdate"
                    color="teal"
                    indeterminate
                    width="2"
                    size="20"
                    >

                    </v-progress-circular>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="red"
                    outlined
                    :disabled="isloadingupdate"
                    @click="cancelupdateidcard"
                    :dark="isloadingupdate ? false : true"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                >
                <v-btn :color="color.theme"
                :disabled="isloadingupdate"
                :dark="isloadingupdate ? false : true"
                @click="fn_updateidcard">อัพเดต</v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="show" max-width="500" v-else>
        <v-card>
            <v-card-text class="pa-5">
                <v-layout class="pt-2 pb-2">
                    <v-flex d-flex justify-center class="ml-8">
                    <span :style="headerPage"> Update ID Card  </span>
                    </v-flex>
                    <v-flex xs1 d-flex justify-end>
                    <v-icon
                        :color="color.theme"
                        @click="cancelupdateidcard"
                        >mdi-close</v-icon
                    >
                </v-flex>
                </v-layout>
                
                <v-layout align-center justify-center class="py-3">
                    <p class="text-center" :style="fontsizeText">
                        ไม่พบเลขบัตรประชาชนนี้บนระบบ ต้องการอัพเดตให้กับผู้ใช้นี้หรือไม่ ?
                    </p>
                </v-layout>

                <div class="text-center">
                    <v-btn
                    :style="btnAction"
                    class="mr-6"
                    height="40px"
                    width="45%"
                    color="red"
                    outlined
                    :disabled="isloadingupdate"
                    @click="cancelupdateidcard"
                    :dark="isloadingupdate ? false : true"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                    >
                    <v-btn
                    class="elevation-0"
                    :style="btnAction"
                    height="40px"
                    width="45%"
                    :color="color.theme"
                    :disabled="isloadingupdate"
                    :dark="isloadingupdate ? false : true"
                    @click="fn_updateidcard">ยืนยัน</v-btn
                    >
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
    props: ["show", "idCard"],
    data () {
        return {
            opendialog_update_id_card: false,
            isloadingupdate: false,
            consent_update_idcard: false,
            
        }
    },
    computed: {
        ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataOneAccountId"
    ]),
        resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return 220;
            case "sm":
            return 400;
            case "md":
            return 500;
            case "lg":
            return 600;
            case "xl":
            return 800;
        }
        },
        headerPage() {
        return (
            "color:" +
            this.color.theme +
            ";" +
            "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
        },
        title() {
        return "color:black; font-size: 14px; line-height: 28px;";
        },
        btnAction() {
        return "font-size: 16px; font-weight: lighter;";
        },
        textfield() {
        return "min-height: 1px;";
        },
        success() {
        return "color:#58A144; font-size: 18px; line-height: 24px;";
        },
        fontsizeText () {
            if(this.resolutionScreen >= 500){
                return "font-size: 18px;"
            } else {
                return "font-size: 16px;"
            }
        }
    },
    methods: {
        async fn_updateidcard () {
            console.log("Hello Update IdCard ")
            this.isloadingupdate = true
            let auth = await gbfGenerate.generateToken();
            let url = "/api/taxbox/update_id_card"
            let payload = {
                accesstoken: this.dataAccesstoken,
                id_card: this.idCard
            }
            await this.axios.post(
                process.env.VUE_APP_SERVICE_DOWNLOAD_API + url, 
                payload, 
                {headers: { Authorization: auth.code }})
            .then((response) => {
                if(response.data.status === 'OK'){
                    this.$emit('closedialogupdate', true, "")
                } else {
                    if(response.data.errorCode === 'ER500'){
                        // Toast.fire({
                        // icon: "error",
                        // title: "ไม่สามารถอัพเดต OneID ได้",
                        // });
                    }
                    this.isloadingupdate = false
                    this.$emit('closedialogupdate', false, "ERR")
                }
            }) 
            .catch((err) => {
                console.log("Error ", err)
                // Toast.fire({
                //     icon: "error",
                //     title: "เกิดข้อผิดพลาดบางอย่าง",
                // });
                this.isloadingupdate = false
                this.$emit('closedialogupdate', false, "ERR")
            })

            // setTimeout(() => {
            //     this.isloadingupdate = false
            //     //call API
            //     // sned
            //     this.$emit('closedialogupdate', true)
            // }, 2000)
        },

        cancelupdateidcard () {
            this.isloadingupdate = false
            this.$emit('closedialogupdate', false, "CANCEL")
        }
    }
}
</script>
